import icons from '../images/projects/wog/icons.png';
import logo from '../images/projects/wog/logo.png';
import logo2 from '../images/projects/wog/logo-1.png';
import identity from '../images/projects/wog/worldofgemstones_4.png';
import cards from '../images/projects/wog/cards.png';
import bcards3d from '../images/projects/wog/bcard3d.jpg';
import discount3d from '../images/projects/wog/discount3d.jpg';
import box3d from '../images/projects/wog/box3d.jpg';

const WogPalette = [
  { id: 1, colorCode: '#DEE5DB', colorName: 'Gainsboro' },
  { id: 2, colorCode: '#97B293', colorName: 'Dark sea green' },
  { id: 3, colorCode: '#354036', colorName: 'Kombu green', isDark: true },
  { id: 4, colorCode: '#10492A', colorName: 'Cal Poly Pomona green', isDark: true },
  { id: 5, colorCode: '#202A25', colorName: 'Charleston green', isDark: true },
  { id: 6, colorCode: '#B98A63', colorName: 'Deer' },
  { id: 7, colorCode: '#E7E5E3', colorName: 'Platinum' },
  { id: 8, colorCode: '#ffffff', colorName: 'White' },
];

export const GalleryWogLogo = [
  { id: 1, src: logo },
  { id: 2, src: logo2 }
];

export default {
  colorPalette: WogPalette,
  HeadImage: discount3d,
  projectName: 'World of Gemstones',
  projectCategories1:'Visual identity',
  projectCategories2:'Art direction',
  projectDeliverables1:'Brand identity elements, packaging,',
  projectDeliverables2:'Online and offline marketing materials',
  topdesc1: 'World of Gemstones(Świat Kamieni) is a small shop based in Poland.',
  topdesc2: 'They offer various high quality minerals from all over the world.',
  descHeader1:'Our role',
  descParagraph1: 'World of Gemstones was opened over 15 years ago, but it did never really had a consistent identity. We had to change that. Our quick research shows that the people who shop at World of Gemstones are mostly women, 30 or older, looking for quality, classic and beauty of nature. Our main focus was to create a brand that will emphasize these values - the one that’s elegant and timeless, but not extravagant. ',
  descHeader2:'',
  descParagraph2:'We’ve decided on the color palette that is at the same time classic and organic(but not unrefined). Deep, dark green paired with pastel beige, pistachio and light grey, complemented with gold accents. Since We’ve really wanted to avoid overused, polygon-shaped, literal representation of gemstones,  we’ve looked for an inspiration in soft, ovoid shapes and linear patterns of art deco era. They are not only much more unique, but also seem to be more classic and elegant. The logomark is a subtle hint. It may be a precious gemstone enclosed on a necklace or a globe. In addition, this small set of icons was developed to represent the various ways minerals are being cut and polished.',
  GalleryLogo: GalleryWogLogo,
  ImageIcons: icons,
  descHeader3:'',
  descParagraph3: 'We were responsible for creating lots of different offline materials - including discount cards, labels, some stationery and invoices.',
  ImageBranding: bcards3d,
  ImageBranding2: identity,
  ImageBranding3: box3d,
  ImageBranding4: cards,
  ImageBranding5: identity,

};
