import React from 'react';
import {ThemeProvider} from 'styled-components';

import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import AllFixedElements from '../components/AllFixedElements';

import SEO from '../components/seo';

import SectionInfoCaseStudy from '../componentsCaseStudy/SectionInfoCaseStudy';
import SectionImageCaseStudy from '../componentsCaseStudy/SectionImageCaseStudy';
import SectionDescCaseStudy  from '../componentsCaseStudy/SectionDescCaseStudy';
import SectionPaletteCaseStudy from '../componentsCaseStudy/SectionPaletteCaseStudy';
import SectionImagesTiles from '../componentsCaseStudy/SectionImagesTiles';
import SectionLastCaseStudy from '../componentsCaseStudy/SectionLastCaseStudy'

import GlobalStyle from '../components/layoutCss';
import SectionFooter from '../components/SectionFooter';

import WogContent from '../data/WogContent';


class WogCaseStudy extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
          <>
            <SEO
              title='World of Gemstones'
              path={this.props.path}
            />
            <AllFixedElements
              isCaseStudy={true}
            />
            <MainContainer>
                <SectionInfoCaseStudy
                  topdesc1={WogContent.topdesc1}
                  topdesc2={WogContent.topdesc2}
                  projectName={WogContent.projectName}
                  projectCategories1={WogContent.projectCategories1}
                  projectCategories2={WogContent.projectCategories2}
                  projectDeliverables1={WogContent.projectDeliverables1}
                  projectDeliverables2 ={WogContent.projectDeliverables2}
                />
                <SectionImageCaseStudy bgImg={WogContent.HeadImage}/>

                {/*----------------------------Logo----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'01'}
                  descHeader={WogContent.descHeader1}
                  descParagraph={WogContent.descParagraph1}
                />
                <SectionDescCaseStudy
                  descCounter ={'02'}
                  descHeader={WogContent.descHeader2}
                  descParagraph={WogContent.descParagraph2}
                />
                <SectionPaletteCaseStudy myColorPalette={WogContent.colorPalette}/>
                <SectionImagesTiles myArray={WogContent.GalleryLogo}/>
                <SectionImageCaseStudy bgImg={WogContent.ImageIcons} />


              {/*----------------------------Visual identity elements----------------------------*/}

              <SectionDescCaseStudy
                descCounter ={'03'}
                descHeader={WogContent.descHeader3}
                descParagraph={WogContent.descParagraph3}
              />
              <SectionImageCaseStudy bgImg={WogContent.ImageBranding} />
              <SectionImageCaseStudy bgImg={WogContent.ImageBranding2} />
              <SectionImageCaseStudy bgImg={WogContent.ImageBranding3} />
              <SectionImageCaseStudy bgImg={WogContent.ImageBranding4} />
              <SectionImageCaseStudy bgImg={WogContent.ImageBranding5} />

                <SectionLastCaseStudy />
                <SectionFooter />
            </MainContainer>
          </>
        </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}

export default WogCaseStudy;
